import React from "react";

import { Route, Switch } from "react-router-dom";
import InviteDealer from "./components/inviteDealer";
import Main from "./components/main";
import UploadHistory from "./components/UploadHistory";
import ViewDealer from "./components/viewDealer";
import VouchersB from "./components/vouchers_B";
import ViewMerchants from "../viewMerchants";
import InviteMerchant from "./components/inviteMerchant";
import WalletHistory from "./components/walletHistory";

class Logical extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      someKey: "someValue",
    };
  }

  render() {
    return (
      <div>
        <Switch>
          <Route
            exact
            path="/logical"
            key={1}
            render={(routerProps) => <Main></Main>}
          />
          <Route
            exact
            path="/logical/invite-dealer"
            key={2}
            render={(routerProps) => <InviteDealer {...this.props}></InviteDealer>}
          />
          <Route
            exact
            path="/logical/view-dealers"
            key={3}
            render={(routerProps) => <ViewDealer {...this.props}></ViewDealer>}
          />
          <Route
            exact
            path="/logical/invite-merchant"
            key={3}
            render={(routerProps) => <InviteMerchant {...this.props}></InviteMerchant>}
          />
          <Route
            exact
            path="/logical/view-merchant"
            key={3}
            render={(routerProps) => <ViewMerchants {...this.props}></ViewMerchants>}
          />
          <Route
            exact
            path="/logical/upload-history"
            key={4}
            render={(routerProps) => <UploadHistory {...this.props}></UploadHistory>}
          />
          <Route
            exact
            path="/logical/purchase-history"
            key={5}
            render={(routerProps) => <VouchersB {...this.props}></VouchersB>}
          />
           <Route
            exact
            path="/logical/wallet-history"
            key={509}
            render={(routerProps) => <WalletHistory {...this.props}></WalletHistory>}
          />
        </Switch>
      </div>
    );
  }

  componentDidMount() {
    this.setState({
      someKey: "otherValue",
    });
  }
}

export default Logical;
